.flex-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	color: white;
	animation: colorSlide 15s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}

.text-center {
	text-align: center;
}

.text-center h1,
.text-center h3 {
	margin: 10px;
	cursor: default;
}

.text-center h1 .fade-in {
	animation: fadeIn 2s ease infinite;
}

.text-center h1 {
	font-size: 8em;
	transition: font-size 200ms ease-in-out;
	border-bottom: 1px dashed white;
}

.text-center h1 #digit1 {
	animation-delay: 200ms;
}

.text-center h1 #digit2 {
	animation-delay: 300ms;
}

.text-center h1 #digit3 {
	animation-delay: 400ms;
}

.text-center button {
	border: 1px solid white;
	background: transparent;
	outline: none;
	padding: 10px 20px;
	font-size: 1.1rem;
	font-weight: bold;
	color: white;
	text-transform: uppercase;
	transition: background-color 200ms ease-in;
	margin: 20px 0;
}

.text-center button:hover {
	background-color: white;
	color: #555;
	cursor: pointer;
}

@keyframes colorSlide {
	0% {
		background-color: #152a68;
	}

	25% {
		background-color: royalblue;
	}

	50% {
		background-color: seagreen;
	}

	75% {
		background-color: tomato;
	}

	100% {
		background-color: #152a68;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
