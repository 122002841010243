.friends-title {
	color: var(--grey);
	font-size: 1.4rem;
}

.friends-link {
	text-decoration: none;
}

.friends-icon {
	margin-right: 1.4rem;
}

.direct-message {
	color: var(--grey);
	font-size: 1.2rem;
}

.plus-icon {
	color: var(--grey);
}

.friends-button {
	font-size: 19px !important;
}

.friend-name:hover {
	color: white;
}

.display {
}

.display.logout {
	color: green;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
}

.display.logout h6 {
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
}
.display.logout h6:first-child {
	width: auto;
	min-width: fit-content;
	max-width: fit-content;
	margin-right: 10px;
}

.display.logout .icon-container {
	flex-shrink: 0;
}

.icon-container {
	color: red;
}

.sub-column {
	height: 70vh;
	max-height: 70vh;
	overflow-y: auto;

	scrollbar-width: thin;
	scrollbar-color: darkslategrey slategrey;

	&::-webkit-scrollbar {
		width: 10px;
	}

	/* Define the styles for the thumb (the draggable part) */
	&::-webkit-scrollbar-thumb {
		background-color: darkslategrey;
		border-radius: 5px; /* Rounded corners for the thumb */
	}

	/* Define the styles for the track (the area the thumb moves along) */
	&::-webkit-scrollbar-track {
		background-color: slategrey;
		border-radius: 5px;
	}
}

a.friend-name {
	display: inline-block;
	width: 90%;
	max-width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 5px 10px;
}

.logout-button {
	width: 40px; /* Adjust the width to your desired size */
	height: 40px; /* Adjust the height to your desired size */
	background-color: red;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.logout-button:hover {
	background-color: darkred;
}
